@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

.box {
  /* color: white; */
  /* padding: 2px; */
  /* text-shadow: 0 1px 0 #000; */

  --border-angle: 0turn;

  /* Updated background to complement the border */
  --main-bg: conic-gradient(
    from var(--border-angle),
    #f6f4f7,
    #f6f4f7 5%,
    #f6f4f7 60%,
    #f6f4f7 95%
  );

  /* Border animation with Green & Yellow */
  --gradient-border: conic-gradient(
    from var(--border-angle),
    transparent 25%,
    #32cd32,
    #ffd700 99%,
    transparent
  );

  /* border: solid 5px transparent; */

  background: var(--main-bg) padding-box, var(--gradient-border) border-box,
    var(--main-bg) border-box;

  background-position: center center;

  animation: bg-spin 1s linear infinite;
}

.dialog-bg {
  background-size: 140%;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .dialog-bg {
    background-image: url("../../assets/spring2.webp");
  }
}

@media (min-width: 769px) {
  .dialog-bg {
    background-image: url("../../assets/spring.webp");
  }
}
