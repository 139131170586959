.county-list {
    display: flex;
    flex-wrap: wrap;
    /* Allow items to wrap onto multiple lines */
    gap: 20px;
    /* Space between items */
    margin-top: 20px;

    /* Space above the list */
}

.county-option {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    /* Default styling for all screens */
    flex: 1 1 100%;
    /* Default to full width on small screens */
}

.county-option:hover {
    background-color: #f0f0f0;
}

.county-option.selected {
    background-color: #4caf50;
    color: white;
    transform: scale(1.05);
}

.county-option.selected:hover {
    background-color: #45a049;
}

/* For medium and larger devices */
@media (min-width: 768px) {
    .county-option {
        flex: 1 1 calc(50% - 10px);
        /* Two options per row, with gap */
    }
}

/* For large devices */
@media (min-width: 1200px) {
    .county-option {
        flex: 1 1 calc(50% - 10px);
        /* Two options per row, with gap */
    }
}

@keyframes colorChange {
    0% {
        color: #4caf50 !important;
        /* Starting color */
    }

    50% {
        color: #ff5722 !important;
        /* Midway color */
    }

    100% {
        color: #4caf50 !important;
        /* Ending color */
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.8;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

.blinking-text {
    animation: colorChange 2s infinite, blink 1s infinite, pulse 1.5s infinite;
    font-weight: bold;
    /* Optional: make the text bold */
    font-size: 18px;
    /* Use a relative unit */
}

.bouncing-text {
    animation: bounce 1s infinite;
    font-weight: bold;
    /* Optional: make the text bold */
    font-size: 18px;
    /* Use a relative unit */
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}